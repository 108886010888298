/* Message.css */

/* Base styles for the message container */
.message-container {
    display: flex;
    flex-direction: row;
}

/* Chat list styles for desktop screens */
.chat-list {
    flex: 1;
    /* Add your chat list styles for desktop here */
}

/* Chatbox styles for mobile screens */
.chatbox-mobile {
    flex: 1;
    /* Add your chatbox styles for mobile here */
}

/* Media query for mobile devices */
@media (max-width: 767px) {

    /* Hide chat list on mobile */
    .chat-list {
        display: none;
    }

    /* Show chatbox on mobile */
    .chatbox-mobile {
        display: block;
    }
}